import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import { fetchEvenements } from '../../services/apiService';
import PaymentForm from './PaymentForm';
import Header from '../components/Header';
import Footer from '../components/Footer';

import { CartContext } from '../../contexts/CartContext';
import DetailHeader from '../detail/DetailHeader';
import PanierItem from '../components/PanierItem';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function AchatComponents() {
    const navigate = useNavigate();
    const [timeLeft, setTimeLeft] = useState(300);
    const [showModal, setShowModal] = useState(false);
    const [eventDetails, setEventDetails] = useState(null);
    const { id } = useParams();
    const { cartItems, updateCartItems } = useContext(CartContext);
    const [quantities, setQuantities] = useState({});
    const [selectionDetails, setSelectionDetails] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        const params = {
            mode: 'getEvenement',
            STR_UTITOKEN: 'wzpjku2dvokriz3phgop',
            LG_EVEID: localStorage.getItem('LG_EVEID')
        };

        fetchEvenements(params)
            .then(response => {
                setEventDetails(response.data.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, [id]);

    useEffect(() => {
        const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
        if (storedCartItems.length === 0) {
            navigate('/detail-event');
        } else {
            updateCartItems(storedCartItems);
        }
    }, [navigate, updateCartItems]);

    useEffect(() => {
        const quantities = {};
        const selectionDetails = cartItems.map(item => {
            quantities[item.category] = item.quantity;
            return {
                ...eventDetails,
                category: item.category,
                quantity: item.quantity,
                price: item.price,
                totalPrice: item.totalPrice,
            };
        });

        setQuantities(quantities);
        setSelectionDetails(selectionDetails);

        const total = cartItems.reduce((acc, item) => acc + item.totalPrice, 0);
        setTotalAmount(total);
    }, [cartItems, eventDetails]);

    const handleRemoveItem = (index) => {
        const updatedCartItems = [...cartItems];
        updatedCartItems.splice(index, 1);
        updateCartItems(updatedCartItems);
        localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    };

   

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    return (
        <>
            <Header />
            <div className={`content`}>
                {eventDetails && eventDetails.map(evenement => (
                    <div key={evenement.id}>
                        <DetailHeader key={evenement.id} evenement={evenement} first_btn={{ link: "/", name: "Retour à la recherche" }} second_btn={{ link: "/detail-event", name: "retour au detail" }} cartItems={cartItems} />
                        <div className="movie-details-section padding-top padding-bottom mt-5 pt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <PaymentForm cartPrice={totalAmount} />
                                    </div>

                                    <div className="col-lg-4">
                                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100  mb-7 me-lg-10">
                                            <div className="card card-flush py-4">
                                                <div className="card-header">
                                                    <div className="card-title">
                                                        <h2>Résumé panier</h2>
                                                    </div>
                                                </div>
                                                <div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: 623 }}>
                                                    <div className="card-body pt-0">
                                                        {cartItems.filter(pannierData => pannierData.quantity > 0).map((pannierData, index) => (
                                                            <PanierItem
                                                                key={index}
                                                                pannierData={pannierData}
                                                                index={index}
                                                                onRemove={() => handleRemoveItem(index)}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="form-group d-flex align-items-centerform-group d-cblock mx-auto align-items-center">
                                                    <div className="btn btn-danger  btn-lg rounded fs-14" >Vider le panier</div>
                                                </div>
                                                {/* <span>{Object.values(quantities).reduce((acc, curr) => acc + curr, 0)} Tickets</span>
                                                <h6 className="subtitle"><span>Total</span><span>{totalAmount} FCFA</span></h6> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                    
                ))}
            </div>
            
        </>
    );
}

export default AchatComponents;

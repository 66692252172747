// src/services/apiService.js

import axios from 'axios';

// const getRootUrl = (port) => {
//   const originWithoutPort = window.location.protocol + '//' + window.location.hostname;
//   const defaultPort = "80";
//   const baseUrl = "/eticketbackend/backoffice/webservices/";
//   //const baseUrl = "/backoffice/webservices/";
//   const finalPort = port ? port : defaultPort;
//   return `http://192.168.181.17:${finalPort}${baseUrl}`;
// };

// const rootUrl = getRootUrl();
//Production

// +++++++++++++++ FONCTION DEPLEPOIUEMENT +++++++++++++++++++
// Je set la racine de l'url dans la session
const getFullUrl = () => {
  const { protocol, hostname, port } = window.location;
  const portPart = port ? `:${port}` : '';
  return `${protocol}//${hostname}/`;
};
const urlBaseImage = "backoffice/"//alert(fullUrl+urlBaseImage);
const rootUrl = getFullUrl() + "backoffice/webservices/"; //Production
localStorage.setItem("urlBaseImage", getFullUrl() + urlBaseImage);


const fetchEvenements = (params) => {
  return axios.post(`${rootUrl}TicketManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

// Vous pouvez ajouter d'autres fonctions pour d'autres appels API ici
// Par exemple, une fonction pour obtenir les détails d'un événement
const fetchEvenementDetails = (eventId) => {
  const params = {
    mode: 'getEvenementDetails',
    LG_EVENID: eventId
    //STR_UTITOKEN: 'wzpjku2dvokriz3phgop',
  };

  return axios.post(`${rootUrl}TicketManager.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};


const doConnexion = (params) => {
  return axios.post(`${rootUrl}Authentification.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const doDisConnexion = (params) => {
  return axios.post(`${rootUrl}Authentification.php`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

const crudData = (params,apiUrl) => {
  return axios.post(`${rootUrl}${apiUrl}`, params, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });
};

export { fetchEvenements, fetchEvenementDetails, doConnexion, doDisConnexion , crudData};

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { doConnexion, crudData } from '../../services/apiService';
import html2pdf from 'html2pdf.js';

const TicketHistory = ({ tickets, searchTerm }) => {
  const urlBaseImage = localStorage.getItem("urlBaseImage");
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 3;
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [response, setResponse] = useState([]);
  const apiUrl = "TicketManager.php";
  const [showModal, setShowModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null); // Nouvel état pour l'élément sélectionné

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (!user) {
      navigate('/'); // Redirige vers la page d'accueil si l'utilisateur est vide
    } else {
      setUserData(user);
    }
  }, [navigate]);

  useEffect(() => {
    if (userData && userData.UTITOKEN) { // Vérifiez que userData et UTITOKEN sont définis
      fetchData({ mode: 'listEvenement', STR_UTITOKEN: userData.UTITOKEN, DT_BEGIN: "2024-01-01", DT_END: "2024-07-06" }, apiUrl, setResponse);
    }
  }, [userData, apiUrl]);

  const fetchData = (params, url, setData) => {
    crudData(params, url)
      .then(response => {
        setData(response.data.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  };

  const filteredTickets = tickets.filter(ticket => 
    ticket.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ticket.date.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalTickets = filteredTickets.reduce((acc, ticket) => {
    const quantity = Number(ticket.quantity);
    return acc + (isNaN(quantity) ? 0 : quantity);
  }, 0);

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = filteredTickets.slice(indexOfFirstTicket, indexOfLastTicket);
  const totalPages = Math.ceil(filteredTickets.length / ticketsPerPage);

  const handleClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEditClick = (ticket) => {
    setSelectedTicket(ticket); // Mettez à jour l'état avec l'élément cliqué
    setShowModal(true);
  };

  const handleExportPdf = () => {
    const element = document.getElementById('ticket-details');
    html2pdf(element);
  };

  return (
    <>
      <div className='container'>
        <div className="card card-flush mb-9 mt-20" id="kt_user_profile_panel">
          <div
            className="card-header rounded-top bgi-size-cover h-200px"
            style={{
              backgroundPosition: "100% 50%",
              backgroundImage: 'url("assets/media/misc/profile-head-bg.jpg")'
            }}
          ></div>
          <div className="card-body mt-n19">
            <div className="m-0">
              <div className="d-flex flex-stack align-items-end pb-4 mt-n19">
                <div className="symbol symbol-125px symbol-lg-150px symbol-fixed position-relative mt-n3">
                  <img src="assets/media/avatars/300-3.jpg" alt="image" className="border border-white border-4"
                    style={{ borderRadius: 20 }}
                  />
                  <div className="position-absolute translate-middle bottom-0 start-100 ms-n1 mb-9 bg-success rounded-circle h-15px w-15px" />
                </div>
              </div>
              <div className="d-flex flex-stack flex-wrap align-items-end">
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <a
                      href="#"
                      className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                    >
                      {/* {userData.SOCNAME} */}
                    </a>
                    <a
                      href="#"
                      className=""
                      data-bs-toggle="tooltip"
                      data-bs-placement="right"
                      aria-label="Account is verified"
                      data-bs-original-title="Account is verified"
                      data-kt-initialized={1}
                    >
                      <i className="ki-duotone ki-verify fs-1 text-primary">
                        <span className="path1" />
                        <span className="path2" />
                      </i>{" "}
                    </a>
                  </div>
                  <span className="fw-bold text-gray-600 fs-6 mb-2 d-block">
                    {/* {userData.UTIFIRSTLASTNAME} */}
                  </span>
                  <div className="d-flex align-items-center flex-wrap fw-semibold fs-7 pe-2">
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-500 text-hover-primary"
                    >
                      UI/UX Design
                    </a>
                    <span className="bullet bullet-dot h-5px w-5px bg-gray-500 mx-3" />
                    <a
                      href="#"
                      className="d-flex align-items-center text-gray-500 text-hover-primary"
                    >
                      Austin, TX
                    </a>
                    <span className="bullet bullet-dot h-5px w-5px bg-gray-500 mx-3" />
                    <a href="#" className="text-gray-500 text-hover-primary">
                      3,450 Followers
                    </a>
                  </div>
                </div>
                <div className="d-flex">
                  <a
                    href="#"
                    className="btn btn-sm btn-light me-3"
                    id="kt_drawer_chat_toggle"
                  >
                    Send Message
                  </a>
                  <button className="btn btn-sm btn-primary" id="kt_user_follow_button">
                    <i className="ki-duotone ki-check fs-2 d-none" />
                    <span className="indicator-label">Follow</span>
                    <span className="indicator-progress">
                      Please wait...{" "}
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row gx-9 gy-6">
        {response.map((ticket, index) => (
          <div className="col-xl-6" data-kt-billing-element="card" key={index}>
            <div className="card card-dashed h-xl-100 flex-row flex-stack flex-wrap p-6">
              <div className="d-flex flex-column py-2">
                <div className="d-flex align-items-center fs-4 fw-bold mb-5">
                  {ticket.STR_EVENAME}
                  {/* <span className="badge badge-light-success fs-7 ms-2">Primary</span> */}
                  <span className={`badge ${ticket.STR_EVESTATUTFREE === "1" ? 'badge-light-danger' : 'badge-light-success'} `}>
                      {ticket.STR_EVESTATUTFREE === "1" ? 'Payant' : 'Gratuit'}
                    </span>
                </div>
                
                <div className="d-flex align-items-center">
                  <img src={urlBaseImage + ticket.STR_EVEPIC} alt="" width={50} className="me-4" />
                  <div>
                    <div className="fs-4 fw-bold">Visa **** 1679</div>
                    <div className="fs-6 fw-semibold text-gray-500">
                      Card expires at 09/24
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center py-2">
                <button
                  className="btn btn-sm btn-light btn-active-light-primary"
                  onClick={() => handleEditClick(ticket)}
                >
                  Edit
                </button>
              </div>
            </div>
          </div>
        ))}
        </div>
      </div>

      {/* Modal */}
      <Modal size='lg' className='' show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
          {selectedTicket && (



              <>

                <div id="ticket-details" class=" flyer ">
                  <div className='container'>

                    <div class="row">
                        <div class="col-md-8 col-xs-12 ">

                            <div class="p-7 left ">
                                {/* <div class="titles fs-12">The quintessence or absolute embodiment of anything; cure-all.</div> */}
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="row d-flex_">
                                            <div class=" col-7 flex-column">
                                                <div class="bgi-no-repeat bgi-position-center min-h-100px min-h-sm-100 bgi-size-cover  h-100" 
                                                style={{
                                                  backgroundImage: `url(${urlBaseImage + selectedTicket.STR_EVEPIC})`,
                                                  backgroundPosition: "center",
                                                  backgroundSize: "cover",
                                                  opacity:"0.4",
                                                  borderRadius : 10

                                                  // style="background-size: 100% 100%;background-image:url('1719916154-01copie2.jpg')
                                                  
                                                  }}>             
                                                  {/* <img src={selectedTicket.STR_EVEPIC}  class="img-fluid"   alt="Image 1"/> */}

                                                </div>
                                                {/* <img src="assets/media/eventpicture/bgYLUQ0e6AWr0C5Qmylq83ZUj70N49A3hPnits7X.png" height="30px" width="40px" alt="Barcode"/> */}

                                            </div>
                                          <div class="col-5 flex-column  ">
                                                <div class="c">
                                                    <img src={urlBaseImage + selectedTicket.STR_EVEPIC}  class="img-fluid"  alt="Image 2"/>
                                                </div>
                                                <div class="col-md-12 p-0">
                                                    <div class="title fs-2">{selectedTicket.STR_EVENAME}</div>
                                                    <div class="description">2021 MAD LAB CREATIVES Co Ltd. 2021 Distributed by Art Gospel Studio All Rights Reserved. Coming Soon March.</div>
                                                </div>
                                                <div class="col-md-12 p-0">
                                                    <div class="title">BAND#1</div>
                                                    <div class="description">2021 MAD LAB CREATIVES Co Ltd. 2021 Distributed by Art Gospel Studio All Rights Reserved. Coming Soon March.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-lg-1 d-flex">
                                        <div class="title text-center rotate-90"> {selectedTicket.STR_EVENAME}</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-xs-12 qr-code-zone">
                            <div class="row align-items-end">
                                <div class="col-md-12 qr-code">
                                  <div class="">
                                    <img src="assets/media/eventpicture/bgYLUQ0e6AWr0C5Qmylq83ZUj70N49A3hPnits7X.png" class="d-block mx-auto"  alt="QR Code"/>
                                  </div>
                                </div>
                                <div class="col-md-12 footer-text mt-0">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                </div>
                                <div class="col-md-12 barcode d-flex d-block mx-auto">
                                    <img src="assets/images/logo/logo_light.svg" class="d-block mx-auto" height="30px" width="40px" alt="Barcode"/>
                                    <img src="bar-code.png" height="30px" alt="Barcode"/>
                                    <img src="assets/media/eventpicture/bgYLUQ0e6AWr0C5Qmylq83ZUj70N49A3hPnits7X.png" height="30px" width="40px" alt="Barcode"/>
                                    {/* <img src="assets/images/logo/logo_light.svg" class=" d-block mx-auto " width="100px" alt="dark logo"></img> */}

                                </div>
                                
                            </div>
                        </div>
                    </div>

                  </div>
                </div>


              </>

           
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleExportPdf}>
            Télécharger
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TicketHistory;

import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

const ticketCategories = [
    { id: 1, title: 'GP', price: 49, image: 'assets/images/billet.png', available: 190, maxseat: 200, maxPurchase: 5, currency: 'FCFA' },
    { id: 2, title: 'VIP', price: 2000, image: 'assets/images/billet.png', available: 5, maxseat: 200, maxPurchase: 2, currency: 'FCFA' },
    { id: 3, title: 'VVIP ', price: 99, image: 'assets/images/billet.png', available: 2, maxseat: 200, maxPurchase: 1, currency: 'FCFA' },
];


const urlBaseImage = localStorage.getItem("urlBaseImage");


const DetailHeader = ({ evenement, cartItems, first_btn, second_btn }) => {
    const [countdown, setCountdown] = useState();
    const [countJour, setCountJour] = useState('');
    const [countHeure, setCountHeure] = useState('');
    const [countMinute, setCountMinute] = useState('');
    const [countSeconde, setCountSeconde] = useState('');

    useEffect(() => {
        if (Array.isArray(evenement)) {
            // Handle multiple events (if needed)
            evenement.forEach((evt) => startCountdown(evt));
        } else if (evenement) {
            // Handle single event
            startCountdown(evenement);
        }
    }, [evenement]);

    const startCountdown = (evt) => {
        const interval = setInterval(() => {
            // Parse the date and time correctly
            const [day, month, year] = evt.DT_EVEBEGIN.split('/');
            const [hours, minutes] = evt.HR_EVEBEGIN.split(':');
            const eventDate = new Date(year, month - 1, day, hours, minutes);

            const now = new Date();
            const difference = eventDate - now;

            if (difference > 0) {
                const days = Math.floor(difference / (1000 * 60 * 60 * 24));
                const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
                const minutes = Math.floor((difference / 1000 / 60) % 60);
                const seconds = Math.floor((difference / 1000) % 60);

                setCountJour(days);
                setCountHeure(hours);
                setCountMinute(minutes);
                setCountSeconde(seconds);

                setCountdown(`${days} Jour -${hours} H-${minutes}:${seconds}s`);
            } else {
                setCountdown("L'événement a commencé !");
                setCountJour("0");
                setCountHeure("00");
                setCountMinute("00");
                setCountSeconde("00");
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    };



    return (
        <div id='detail-header'>
            {/* <section className="details-banner bg_img" style={{ backgroundImage: `url(${evenement.STR_EVEBANNER})`, backgroundSize: "cover", backgroundPosition: "center" }}> */}
            <section className="details-banner bg_img" style={{
                //backgroundImage: evenement.STR_EVEPIC === "assets/media/eventpicture/" ? `url(${evenement.STR_EVEPIC})` : `url(${evenement.STR_EVEBANNER})`,
                backgroundImage:`url(${urlBaseImage + evenement.STR_EVEPIC})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                
                }}>
                <div className="container">
                    <div className="details-banner-wrapper">
                        <h2 id='event-name-mobile' class="title detail-title truncate-2-lines text-theme">{evenement.STR_EVENAME}</h2>
                        <div className="card bgi-position-y-bottom bgi-position-x-end bgi-no-repeat bgi-size-cover min-h-500px mb-5 mb-xl-10 border-0 bg-primary"
                        style={{
                            // backgroundPosition: "50% 50%",
                            // backgroundSize: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundImage: `url(${ urlBaseImage + evenement.STR_EVEPIC})`
                        }}
                        >
                        {/*begin::Body*/}
                        {/* <div className="card-body d-flex flex-column justify-content-center align-items-start ps-lg-15">
                            <h3 className="text-white d-flex fs-2x fw-bolder mb-2 mb-lg-4">
                            Help &amp; Support
                            </h3>
                            <div className="text-white fs-4 fw-bold mb-4 mb-lg-8">
                            Our customer service is about empathy
                            </div>
                            <div className="pt-2 pt-lg-4">
                            <a href="pages/contact.html" className="btn btn-success fw-semibold">
                                Contact Us{" "}
                            </a>
                            </div>
                        </div>*/}
                        </div> 

                        {/* <div id='detail-baner' className="details-banner-content container">
                            <div className="details-banner-thumb ">
                                <img className='card mb-6' src={evenement.STR_EVEPIC} alt={evenement.STR_EVEPIC} />
                            </div>

                           <div className='movie-grid'>
                                <div className="movie-thumb c-thumb">
                                    <img src={evenement.STR_EVEPIC} alt="movie" />
                                </div>
                           </div>
                            
                            
                        </div> */}
                    </div>
                </div>
            </section>
            <section className="book-sections bg-white">
                <div className="container">
                    <div class="book-wrapper py-7">
                        {/* <div class="">
                            <div className="text-center mb-1">

                                <NavLink className="btn btn-sm btn-primary me-2" to={first_btn.link}>
                                    {first_btn.name}
                                </NavLink>
                                {cartItems.length > 0 && (
                                    <NavLink className="btn btn-sm btn-light me-2" to={second_btn.link}>
                                        {second_btn.name}
                                    </NavLink>
                                )}
                            </div>
                        </div> */}
                        <div>
                            <div className="social-and-duration ">
                            <h2 id='event-name-desktop' className="titles detail-title truncate-2-lines text-theme">{evenement.STR_EVENAME}</h2>
                                <div class="duration-area d-flex align-items-center">
                                    <a href="#0" className={`p-3 badge ${evenement.STR_EVESTATUTFREE == 1 ? 'badge-danger ' : 'badge-success'}`}>{evenement.STR_EVESTATUTFREE == 1 ? 'Payant' : 'Gratuit'}</a>
                                    <div class="item ms-4"><i class="fas fa-calendar-alt"></i><span>{evenement.DT_EVEBEGIN}</span></div>
                                    <div class="item ms-4"><i class="far fa-clock"></i><span>{evenement.HR_EVEBEGIN}</span></div>
                                </div>
                            </div>
                        {/* <h3 className="text-gray-100 fw-bold my-7">Type de ticket disponible</h3>

                        <div className='d-flex  w-50'>
                            {ticketCategories && ticketCategories.map((categoreItem, index) => (
                                <div key={index} className="notice d-flex bg-light-primary rounded border-primary border border-dashed me-3 mb-5 p-6">
                                    <div className="d-flex flex-stack flex-grow-1 ">
                                        <div className=" fw-semibold">
                                            <h4 className="text-gray-900 fw-bold mb-0">{categoreItem.title}</h4>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div> */}
                        </div>
                        <ul className="nav nav-pills nav-pills-custom mt-3" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a class="nav-link d-flex  flex-column flex-center overflow-hidden w-70px w-75px py-4 active">
                                    <h3 class=" text-gray-600 fw-bold mb-0 fs-1 compte-a-rebour">{countJour}</h3>
                                    <span class=" text-gray-600 fw-bold fs-8 lh-1 compte-a-rebour">Jour</span>
                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary border-bottom"></span>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a class="nav-link d-flex  flex-column flex-center overflow-hidden w-70px w-75px py-4 active">
                                    <h3 class=" text-gray-600 fw-bold mb-0 fs-1 compte-a-rebour">{countHeure}</h3>
                                    <span class=" text-gray-600 fw-bold fs-8 lh-1 compte-a-rebour">Heure</span>
                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary border-bottom"></span>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a class="nav-link d-flex  flex-column flex-center overflow-hidden w-70px w-75px py-4 active">
                                    <h3 class=" text-gray-600 fw-bold mb-0 fs-1 compte-a-rebour">{countMinute}</h3>
                                    <span class=" text-gray-600 fw-bold fs-8 lh-1 compte-a-rebour">Min</span>
                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary border-bottom"></span>
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a class="nav-link d-flex  flex-column flex-center overflow-hidden w-70px w-75px py-4 active">
                                    <h3 class=" text-gray-600 fw-bold mb-0 fs-1 compte-a-rebour">{countSeconde}</h3>
                                    <span class=" text-gray-600 fw-bold fs-8 lh-1 compte-a-rebour">Sec</span>
                                    <span class="bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary border-bottom"></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DetailHeader;

import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AchatComponents from './pages/paiement/AchatComponents';
import HistoriqueTicket from './pages/profile/HistoriqueTicket';
import Login from './pages/profile/Login';
import Detail from './pages/detail/Detail';
import WarningContent from './pages/Accueil/WarningContent';
import Accueil from './pages/Accueil/Accueil';



function App() {
  return (

    <div>
      <Router basename="/">
        <Routes>
        <Route path="/" element={<WarningContent />} />
        {/* <Route path="/" element={<Accueil />} /> */}
        <Route path="/detail-event" element={<Detail />} />
        <Route path="/mon-profile" element={<HistoriqueTicket />} />
        <Route path="/login" element={<Login />} />
        <Route path="/paiement" element={<AchatComponents />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

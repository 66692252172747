import React, { useState, useEffect } from 'react';
import { doConnexion, crudData } from '../../services/apiService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const PaymentForm = ({cartPrice}) => {
  const [cartItems, setCartItems] = useState([]);
  const [response, setResponse] = useState([]);
  const [showAlert, setShowAlert] = useState(false); // State for showing alert
  const apiUrl = "TicketManager.php";

  // Tableau d'objets pour les options de paiement avec formFieldsName, formLabel, inputTypes et colClasses
  const paymentOptions = [
    { id: 1, name: 'Orange Money', image: 'assets/images/payment/om.png',
      formFieldsName: ['STR_DEBITCARDID', 'STR_DEBITCODE', 'STR_TICPHONE', 'STR_TICMAIL'],
      formLabel: ['Nom', 'Prénom', 'Numero de téléphone', 'Email'],
      inputTypes: ['text', 'text', 'number', 'email'],
      colClasses: [ 'col-lg-6', 'col-lg-6', 'col-lg-6', 'col-lg-6']
    },
    { id: 2, name: 'Carte de Crédit', image: 'assets/images/payment/card.png',
      formFieldsName: ['STR_DEBITCARDID', 'STR_DEBITCARDUSERNAME', 'STR_DEBITCARDEXPIRE', 'STR_DEBITCODE', 'STR_TICPHONE', 'STR_TICMAIL'],
      formLabel: ['Détails de la Carte de Débit', 'Nom sur la Carte de Débit', 'Expiration', 'CVV', 'Numero de téléphone', 'Email'],
      inputTypes: ['text', 'text', 'text', 'text', 'number', 'email'],
      colClasses: ['col-lg-6', 'col-lg-6', 'col-lg-6', 'col-lg-6', 'col-lg-6', 'col-lg-6']
    },
    
  ];

  // État pour suivre l'option de paiement sélectionnée, initialisée avec l'ID de l'option par défaut
  const [selectedOption, setSelectedOption] = useState(paymentOptions[0].id);

  // État pour les valeurs des champs de formulaire
  const [formValues, setFormValues] = useState({});

  // Gestionnaire d'événements pour changer l'option de paiement sélectionnée
  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);
  };

  // Gestionnaire d'événements pour les changements de valeurs des champs de formulaire
  const handleInputChange = (event, fieldName) => {
    setFormValues({
      ...formValues,
      [fieldName]: event.target.value
    });
  };

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
    setCartItems(storedCartItems);
  }, []);

  const nouveauPanier = cartItems.reduce((acc, item) => {
    const newItems = new Array(item.quantity).fill().map(() => ({
      ...item,
      quantity: 1,
      totalPrice: item.price
    }));
    return [...acc, ...newItems];
  }, []);

  // General fetch function
  const fetchData = (params, url, setData) => {
    crudData(params, url)
      .then(response => {
        setData(response.data.data);
        toast.success('Paiement effectué avec succès !'); // Success toast
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
        toast.error('Erreur lors du paiement.'); // Error toast
      });
  };

  const envoyerPanier = async () => {
    setShowAlert(false); // Hide alert before proceeding
    for (const item of nouveauPanier) {
      const itemWithMode = {
        ...item,
        mode: 'createTicket',
        STR_EVENAME: item.eventName,
        DT_EVEBEGIN: item.eventDate,
        DBL_TICAMOUNT: item.price,
        STR_DEVISE: item.currency,
        STR_EVEPIC: item.image,
        ...formValues // Merge form values directly into the item
      };
      console.log('Item avec mode:', itemWithMode);
      fetchData(itemWithMode, apiUrl, setResponse);
    }
  };

  const handlePaymentClick = () => {
    MySwal.fire({
      title: 'Êtes-vous sûr de vouloir effectuer le paiement ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Oui, effectuer le paiement',
      cancelButtonText: 'Annuler',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        envoyerPanier(); // Proceed with payment if confirmed
      }
    });
  };

  return (
    <>
      <div className="checkout-widget checkout-card bg-white boder-none ">
          <h5 className="title">Option de Paiement</h5>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6 '>
                <ul className="payment-option mb-0">
                  {paymentOptions.map(option => (
                    <li cl key={option.id} className={selectedOption === option.id ? 'active pb-0' : 'pb-0'} onClick={() => handleOptionClick(option.id)}>
                      <a>
                        <img src={option.image} alt={option.name} />
                        <span>{option.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='notice d-flex bg-light-success rounded border-success border border-dashed mb-9 p-6 '>
                
                {cartPrice}
              </div>
            </div>
          </div>
          {selectedOption && (
            <div>
              <h6 className="subtitle">Entrez les détails de votre {paymentOptions.find(option => option.id === selectedOption).name}</h6>
              <form className="payment-card-form">

                {/* <div className='row w-100'>
                  {paymentOptions.find(option => option.id === selectedOption).formFieldsName.map((fieldName, index) => (
                    
                    <div key={index} className={`form-group ${paymentOptions.find(option => option.id === selectedOption).colClasses[index]}`}>
                      <div className="d-flex flex-column mb-4 fv-row fv-plugins-icon-container">
                      <label className='required d-flex align-items-center fs-6 fw-semibold mb-2' htmlFor={`field${index}`}>
                        {paymentOptions.find(option => option.id === selectedOption).formLabel[index]}
                          <i className="fas fa-info text-gray-500 fs-10"> </i>
                      </label>
                      <input
                              type={paymentOptions.find(option => option.id === selectedOption).inputTypes[index]}
                              className="form-control form-control-solid"
                        id={`field${index}`}
                        name={fieldName}
                        placeholder={paymentOptions.find(option => option.id === selectedOption).formLabel[index]}
                        onChange={(e) => handleInputChange(e, fieldName)}
                      />
                    </div>
                      
                    </div>
                  ))}
                </div> */}
                <div className="form-group">
                  <div className="btn btn-success  rounded fs-14" onClick={handlePaymentClick}>Effectuer le Paiement</div>
                </div>
              </form>
            </div>
          )}
          <p className="notice">En cliquant sur "Effectuer le Paiement", vous acceptez les <a href="#0">termes et conditions</a></p>
        </div>
      <ToastContainer /> {/* Toast container for displaying notifications */}
    </>
  );
};

export default PaymentForm;

import React, { useState, useEffect, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import { CartContext } from '../../contexts/CartContext';
import PanierItem from '../components/PanierItem';
import { fetchEvenements } from '../../services/apiService';

const TicketOrder = ({ eventDetails }) => {
  const { cartItems, updateCartItems } = useContext(CartContext);
  const [ticketCategories, setTicketCategories] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [availableTickets, setAvailableTickets] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Récupérer et transformer les données de l'API
  useEffect(() => {
    const params = {
      mode: 'listCategorieplaceEvenement',
      LG_EVEID: localStorage.getItem('LG_EVEID')
    };

    fetchEvenements(params)
      .then(response => {
        const apiData = response.data.data;
        const transformedData = apiData.map(item => ({
          id: item.LG_ELIID,
          title: item.STR_LSTDESCRPTION,
          lg_lstid:item.LG_LSTID,
          price: parseFloat(item.DBL_ELIAMOUNT),
          image: 'assets/images/billet.png', // Assurez-vous que c'est l'image correcte
          available: parseInt(item.INT_ELINUMBER, 10),
          maxseat: parseInt(item.INT_ELINUMBERMAX, 10),
          maxPurchase: parseInt(item.INT_ELINUMBERMAX, 10), // Utiliser INT_ELINUMBERMAX pour maxPurchase
          currency: 'GNF'
        }));
        setTicketCategories(transformedData);

        // Initialiser les états `quantities` et `availableTickets` basés sur les données transformées
        const initialQuantities = transformedData.reduce((acc, category) => {
          acc[category.id] = 0;
          return acc;
        }, {});

        const initialAvailableTickets = transformedData.reduce((acc, category) => {
          acc[category.id] = category.available;
          return acc;
        }, {});

        setQuantities(initialQuantities);
        setAvailableTickets(initialAvailableTickets);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des données:', error);
      });
  }, []);

  // Gérer le clic sur le bouton de paiement
  const handlePaymentClick = () => {
    const existingCartItems = [...cartItems];

    ticketCategories
      .filter(category => quantities[category.id] > 0)
      .forEach(category => {
        const existingItemIndex = existingCartItems.findIndex(item =>
          item.category === category.title && item.LG_EVEID === eventDetails.LG_EVEID
        );

        if (existingItemIndex !== -1) {
          existingCartItems[existingItemIndex] = {
            ...existingCartItems[existingItemIndex],
            quantity: quantities[category.id],
            INT_ELINUMBER: quantities[category.id],
            totalPrice: quantities[category.id] * category.price
          };
        } else {
          existingCartItems.push({
            LG_EVEID: eventDetails.LG_EVEID,
            //STR_UTITOKEN: "wzpjku2dvokriz3phgoq",
            STR_TICMAIL: "",
            LG_LSTID: category.lg_lstid,
            STR_EVENAME: eventDetails.STR_EVENAME,
            STR_EVEPIC_PANIER: eventDetails.STR_EVEPIC,
            DT_EVEBEGIN: eventDetails.DT_EVEBEGIN,
            category: category.title,
            quantity: quantities[category.id],
            INT_ELINUMBER: quantities[category.id],
            DBL_TICAMOUNT: category.price,
            totalPrice: quantities[category.id] * category.price,
            STR_DEVISE: category.currency,
            STR_EVEPIC: category.image,
            STR_MAX_PURCHASE: category.maxPurchase,
            mode: "createTicket"
          });
        }
      });

    const updatedTotalAmount = ticketCategories.reduce((total, category) => {
      return total + (quantities[category.id] * category.price);
    }, 0);

    updateCartItems(existingCartItems);

    localStorage.setItem('totalAmount', updatedTotalAmount.toFixed(2));
    localStorage.setItem('quantities', JSON.stringify(quantities));

    toast.success("Vos choix ont été ajoutés au panier !");

    // Réinitialiser les quantités et les tickets disponibles
    resetCategories();
  };

  // Réinitialiser les catégories
  const resetCategories = () => {
    setQuantities(ticketCategories.reduce((acc, category) => {
      acc[category.id] = 0;
      return acc;
    }, {}));

    setAvailableTickets(ticketCategories.reduce((acc, category) => {
      acc[category.id] = category.available;
      return acc;
    }, {}));
    setIsButtonDisabled(true); // Désactiver le bouton après la réinitialisation
  };

  // Gérer le changement de quantité
  const handleQuantityChange = (id, change) => {
    const newQuantities = { ...quantities };
    const newAvailableTickets = { ...availableTickets };
    const category = ticketCategories.find(cat => cat.id === id);

    if (change > 0 && (newAvailableTickets[id] === 0 || newQuantities[id] >= category.maxPurchase)) {
      toast.error(`Vous ne pouvez pas acheter plus de ${category.maxPurchase} tickets pour ${category.title}.`);
      return;
    }

    newQuantities[id] = (newQuantities[id] || 0) + change;
    if (newQuantities[id] < 0) newQuantities[id] = 0;

    newAvailableTickets[id] -= change;
    if (newAvailableTickets[id] < 0) newAvailableTickets[id] = 0;

    setQuantities(newQuantities);
    setAvailableTickets(newAvailableTickets);

    const newTotalAmount = ticketCategories.reduce((total, category) => {
      return total + (newQuantities[category.id] * category.price);
    }, 0);

    setTotalAmount(newTotalAmount);

    // Activer ou désactiver le bouton en fonction de la quantité totale
    const totalTickets = Object.values(newQuantities).reduce((acc, curr) => acc + curr, 0);
    setIsButtonDisabled(totalTickets === 0);
  };

  return (
    <>
      <ToastContainer />
      <div  className="card card-flush py-4 mb-10">
        <div className="card-body pt-0 bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-250px">
          <div className="pb-12">
            <h1 className="fw-bold text-gray-900 text-theme">Choisir mon ticket</h1>
            <div className="text-muted fw-semibold fs-4 text-theme">
              Choisissez la quantité par catégorie de ticket pour continuer
            </div>
          </div>
          <div id='ticketCategories' className="row justify-content-center mb-0">
            {ticketCategories.filter(category => availableTickets[category.id] > 0).map(category => (
              
            
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-6 col-12" key={category.id}>

              
{/* <div className="border border-dashed border-gray-300 rounded px-7 py-3 mb-6">
  <div className="d-flex flex-stack mb-3">
    <div className="me-3">
      <img
        src="/oliver-html-pro/assets/media/stock/ecommerce/210.png"
        className="w-50px ms-n1 me-1"
        alt=""
      />
      <a
        href="/oliver-html-pro/apps/ecommerce/catalog/edit-product.html"
        className="text-gray-800 text-hover-primary fw-bold"
      >
        Elephant 1802
      </a>
    </div>
    <div className="m-0">
      <button
        className="btn btn-icon btn-color-gray-500 btn-active-color-primary justify-content-end"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-overflow="true"
      >
        <i className="ki-duotone ki-dots-square fs-1">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
        </i>
      </button>
    </div>
  </div>
  <div className="d-flex flex-stack">
    <span className="text-gray-500 fw-bold">
      To:
      <a
        href="/oliver-html-pro/apps/ecommerce/sales/details.html"
        className="text-gray-800 text-hover-primary fw-bold"
      >
        Jason Bourne{" "}
      </a>
    </span>
    <span className="badge badge-light-success">Delivered</span>
  </div>
</div> */}

                <div className="plan plan--accents plan--light w-100">
                  <h2 className="plan-title">{category.title}</h2>
                  <p className="plan-price"><span>{category.price} <sup>{category.currency}</sup></span></p>
                  <p className="plan-price text-center my-10">
                    {quantities[category.id] === 0 ? '0' : quantities[category.id] * category.price} <sup className='fs-7'>{category.currency}</sup>
                  </p>
                  <div className=''>
                    <div className="cart-plus-minus text-dark d-block mx-auto">
                      <Tippy className="custom-tooltip" content="Réduire le nombre" arrow={true}>
                        <div className="dec qtybutton" onClick={() => handleQuantityChange(category.id, -1)}>-</div>
                      </Tippy>
                      <input className="cart-plus-minus-box text-dark w-100" type="text" value={quantities[category.id]} readOnly />
                      <Tippy className="custom-tooltip" content="Augmenter le nombre" arrow={true}>
                        <div className="inc qtybutton" onClick={() => handleQuantityChange(category.id, 1)}>+</div>
                      </Tippy>
                    </div>
                  </div>
                  <div className="pt-0">
                    {/* <p className="mb-0"><strong>Disponible:</strong> {availableTickets[category.id]}</p> */}
                    <p className='text-center'><strong>Max Par Achat:</strong> {category.maxPurchase}</p>
                  </div>
                </div>
              </div>
              
            ))}
          </div>
          <div className="row justify-content-center mt-20">
            <div className="col-lg-8 col-md-8 col-sm-10 col-12 text-center">
              <button type="button" className="btn btn-dark" disabled={isButtonDisabled} onClick={handlePaymentClick}>
                Valider
              </button>
            </div>
          </div>
          <div className="row justify-content-center mt-5">
            <div className="col-lg-8 col-md-8 col-sm-10 col-12 text-center">
              <p className="mb-0"><strong>Total:</strong> {totalAmount} GNF</p>
              <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
              <small className="text-muted">Les tickets sont non remboursables</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TicketOrder;

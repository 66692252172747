import React, { useState, useEffect, useContext } from 'react';
import TicketOrder from './TicketOrder';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { fetchEvenements, crudData } from '../../services/apiService';
import SeatMap from './SeatMap';
import Swal from 'sweetalert2';
import { CartContext } from '../../contexts/CartContext';
import DetailHeader from './DetailHeader';
import OrangeMoneyForm from './OrangeMoneyForm';
import RecuperationTicket from './RecuperationTicket';
import Switch from 'react-switch';
import Reservations from '../components/Reservations';


const urlBaseImage = localStorage.getItem("urlBaseImage"); // La base de l'url des images
const paymentOptions = [
    { id: 1, type: 'mobile-money', name: 'Orange Money', image: 'assets/images/payment/om.png', STR_CURRENCY:'OUV',STR_PROVIDER:"orangemoney" },
    { id: 2, type: 'mobile-money', name: 'Mtn Money', image: 'assets/images/payment/logo-mtn.png', STR_CURRENCY:'GNF',STR_PROVIDER:"mtnmoney" },
];


function Detail() {
    const [eventDetails, setEventDetails] = useState(null);
    const [tokenNotification, setTokenNotification] = useState(null);
    const [paymentProvider, setPaymentProvider] = useState(null); // Nouvel état pour le fournisseur de paiement
    const { cartItems, clearCart } = useContext(CartContext);
    const [showGetTicket, setShowGetTicket] = useState(false); // State to control visibility of "Obtenir mon ticket"
    const [ticketFieldsFilled, setTicketFieldsFilled] = useState(false); // State to track if ticket fields are filled
    const [loading, setLoading] = useState(false); 
    const [paymentCompleted, setPaymentCompleted] = useState(false);

    
    // State for loader


    useEffect(() => {
        const params = {
            mode: 'getEvenement',
            LG_EVEID: localStorage.getItem('LG_EVEID')
        };


        fetchEvenements(params)
            .then(response => {
                setEventDetails(response.data);
            })
            .catch(error => {
                console.error('Erreur lors de la récupération des données:', error);
            });
    }, []);

    useEffect(() => {
        // Check if cart is not empty to show "Obtenir mon ticket"
        if (cartItems.length > 0) {
            setShowGetTicket(true);
        } else {
            setShowGetTicket(false);
        }
    }, [cartItems]);


    const [selectedOption, setSelectedOption] = useState(paymentOptions[0].id);

    const handleOptionClick = (optionId) => {
        setSelectedOption(optionId);
    };


    const [selectedGetitcketOption, setSelectedGetitcketOption] = useState(1);
    const [telephone, setTelephone] = useState('');
    const [email, setEmail] = useState('');
    const [whatsappNumber, setWhatsappNumber] = useState('');
    const [confirmWhatsappNumber, setConfirmWhatsappNumber] = useState('');

    const [mtnPhonePayment, setMtnPhonePayment] = useState('');
    const [orangePhonePayment, setOrangePhonePayment] = useState('');

    const handleOptionGetitcketChange = (e) => {
        setSelectedGetitcketOption(parseInt(e.target.value));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'STR_TICPHONE') {
            setTelephone(value);
        } else if (name === 'STR_TICMAIL') {
            setEmail(value);
        } else if (name === 'whatsappNumber') {
            setWhatsappNumber(value);
        } else if (name === 'confirmWhatsappNumber') {
            setConfirmWhatsappNumber(value);
        } else if (name === 'STR_TICPHONEPAYMENT_MTN') {
            setMtnPhonePayment(value);
        } else if (name === 'STR_TICPHONEPAYMENT_ORANGE') {
            setOrangePhonePayment(value);
        }
    };
    

    const shouldShowConfirmation = () => {
        if (selectedGetitcketOption === 1 && telephone !== '') {
            return true; // Afficher si le numéro de téléphone est renseigné
        } else if (selectedGetitcketOption === 2 && whatsappNumber !== '' && whatsappNumber === confirmWhatsappNumber) {
            return true; // Afficher si les numéros WhatsApp sont identiques
        }
        return false;
    };

    // À l'intérieur de votre composant
    const [conditionsAccepted, setConditionsAccepted] = useState(false);

    // Fonction pour gérer le changement de la case à cocher
    const handleConditionsChange = (event) => {
    setConditionsAccepted(event.target.checked);
    };

    const totalAmount = cartItems.reduce((total, item) => total + item.totalPrice, 0);

    
    // const handlePayment = async () => {
    //     if (!conditionsAccepted) {
    //         //alert('Veuillez accepter les conditions avant de procéder au paiement.');
    //         return;
    //     }
    //     const selectedPaymentOption = paymentOptions.find(option => option.id === selectedOption);
    //     const params = {
    //         mode: 'createTicket',
    //         STR_TICMAIL: email,
    //         STR_CURRENCY: selectedPaymentOption.STR_CURRENCY,
    //         STR_TICPHONE: selectedOption === 1 ? telephone : mtnPhonePayment, //telephone,
    //         STR_PROVIDER: selectedPaymentOption.STR_PROVIDER,
    //         STR_TICPHONEPAYMENT: selectedOption === 1 ? orangePhonePayment : mtnPhonePayment,
    //         LG_EVEGLOBALID: localStorage.getItem('cartItems'),
    //     };
    
    //     setLoading(true);
    
    //     try {
    //         const response = await crudData(params, "TicketManager.php");
    //         if (response.status === 200) {
    //             const data = response.data;
    //             if (data.code_statut === "1") {
    //                 setTokenNotification(data.token_notification);
    //                 setPaymentProvider(selectedPaymentOption.STR_PROVIDER); // Stockez le fournisseur de paiement
    //                 // Ouvrir un nouvel onglet avec payment_url
    //                 if (selectedOption === 1) {
    //                     window.open(data.payment_url, '_blank');
    //                 }
                    
    //                 // Appeler verifyPayment après un délai
    //                 setTimeout(() => {
    //                     verifyPayment();
    //                 }, 10000); // Vérifie le paiement après 5 secondes
                    
    //             } else {
    //                 console.error('Erreur de paiement:', data);
    //             }
    //         } else {
    //             console.error('Erreur de paiement:', response.data);
    //         }
    //     } catch (error) {
    //         // Gérer les erreurs de requête
    //         console.error('Erreur lors de la requête de paiement:', error);
    //         //alert('Erreur lors de la requête de paiement');
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Statut paiement',
    //             text: 'Erreur lors de la requête de paiement',
    //         });
    //     }
    // };
    
    // const verifyPayment = async () => {
    //     const params = {
    //         mode: 'verifypayment',
    //         STR_PROVIDER: paymentProvider,
    //         LG_CTRID: tokenNotification,
    //     };
    
    //     try {
    //         const response = await crudData(params, "CashManager.php");
    //         if (response.status === 200) {
    //             const data = response.data;
    //             if (data.code_statut === "2") {
    //                 setLoading(true);
    //                 setPaymentCompleted(false); // Arrêter l'intervalle

    //                 /*Swal.fire({
    //                     icon: 'info',
    //                     title: 'Paiement en attente',
    //                     text: data.desc_statut || 'Votre paiement est en attente.',
    //                 });*/
    //             } else if (data.code_statut === "1") {
    //                 localStorage.removeItem('cartItems');
    //                 clearCart();
    //                 setLoading(false);
    //                 setPaymentCompleted(true); // Arrêter l'intervalle
    //                 Swal.fire({
    //                     icon: 'success',
    //                     title: 'Statut du paiement',
    //                     text: data.desc_statut,
    //                     confirmButtonText: 'OK'
    //                 }).then((result) => {
    //                     if (result.isConfirmed) {
    //                         window.location.href = '/';
    //                     }
    //                 });
    //             } else if (data.code_statut === "0") {
    //                 setLoading(false);
    //                 setPaymentCompleted(true); // Arrêter l'intervalle
    //                 Swal.fire({
    //                     icon: 'error',
    //                     title: 'Statut du paiement',
    //                     text: data.desc_statut,
    //                 });
    //             } else {
    //                 setLoading(false);
    //                 setPaymentCompleted(true); // Arrêter l'intervalle
    //             }
    //         } else {
    //             console.error('Erreur:', response.data);
    //         }
    //     } catch (error) {
    //         console.error('Erreur lors de la requête:', error);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Erreur lors de la requête',
    //             text: 'Une erreur est survenue lors de la requête.',
    //         });
    //     }
    // };
    
    // useEffect(() => {
    //     let intervalId;
    //     if (tokenNotification && !paymentCompleted) {
    //         intervalId = setInterval(() => {
    //             verifyPayment();
    //         }, 10000);
    //     }
    
    //     return () => clearInterval(intervalId);
    // }, [tokenNotification, paymentCompleted]);


    const handlePayment = async () => {
        if (!conditionsAccepted) {
            //alert('Veuillez accepter les conditions avant de procéder au paiement.');
            return;
        }
        const selectedPaymentOption = paymentOptions.find(option => option.id === selectedOption);
        const params = {
            mode: 'createTicket',
            STR_TICMAIL: email,
            STR_CURRENCY: selectedPaymentOption.STR_CURRENCY,
            STR_TICPHONE: selectedOption === 1 ? telephone : mtnPhonePayment, //telephone,
            STR_PROVIDER: selectedPaymentOption.STR_PROVIDER,
            STR_TICPHONEPAYMENT: selectedOption === 1 ? orangePhonePayment : mtnPhonePayment,
            LG_EVEGLOBALID: localStorage.getItem('cartItems'),
        };
    
        setLoading(true);
    
        try {
            const response = await crudData(params, "TicketManager.php");
            if (response.status === 200) {
                const data = response.data;
                if (data.code_statut === "1") {
                    setTokenNotification(data.token_notification);
                    setPaymentProvider(selectedPaymentOption.STR_PROVIDER); // Stockez le fournisseur de paiement
                    // Ouvrir un nouvel onglet avec payment_url
                    if (selectedOption === 1) {
                        window.open(data.payment_url, '_blank');
                    }
                    
                    // Démarrer la vérification de paiement avec setInterval
                    const intervalId = setInterval(async () => {
                        const verifyParams = {
                            mode: 'verifypayment',
                            STR_PROVIDER: selectedPaymentOption.STR_PROVIDER,
                            LG_CTRID: data.token_notification,
                        };
        
                        try {
                            const verifyResponse = await crudData(verifyParams, "CashManager.php");
                            if (verifyResponse.status === 200) {
                                const verifyData = verifyResponse.data;
                                if (verifyData.code_statut === "2") {
                                    setLoading(true);
                                } else if (verifyData.code_statut === "1") {
                                    clearInterval(intervalId);
                                    localStorage.removeItem('cartItems');
                                    clearCart();
                                    setLoading(false);
                                    setPaymentCompleted(true);
                                    Swal.fire({
                                        icon: 'success',
                                        title: 'Statut du paiement',
                                        text: verifyData.desc_statut,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.isConfirmed) {
                                            window.location.href = '/';
                                        }
                                    });
                                } else {
                                    clearInterval(intervalId);
                                    setLoading(false);
                                    setPaymentCompleted(true);
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Statut du paiement',
                                        text: verifyData.desc_statut,
                                    });
                                }
                            } else {
                                console.error('Erreur:', verifyResponse.data);
                            }
                        } catch (error) {
                            console.error('Erreur lors de la requête:', error);
                            Swal.fire({
                                icon: 'error',
                                title: 'Erreur lors de la requête',
                                text: 'Une erreur est survenue lors de la requête.',
                            });
                        }
                    }, 10000); // Vérifie le paiement toutes les 10 secondes
                } else {
                    console.error('Erreur de paiement:', data);
                }
            } else {
                console.error('Erreur de paiement:', response.data);
            }
        } catch (error) {
            // Gérer les erreurs de requête
            console.error('Erreur lors de la requête de paiement:', error);
            //alert('Erreur lors de la requête de paiement');
            Swal.fire({
                icon: 'error',
                title: 'Statut paiement',
                text: 'Erreur lors de la requête de paiement',
            });
        }
    };
    
    
    

    return (
        <>
            <Header />
            <div className={`content`}>
                {eventDetails && (
                    <div key={eventDetails.LG_EVEID}>
                        <DetailHeader
                            evenement={eventDetails}
                            first_btn={{ link: "/", name: "Retour à la recherche" }}
                            second_btn={{ link: "/paiement", name: "Retour au paiement" }}
                            cartItems={cartItems}
                        />
                        <div className="movie-details-section padding-top padding-bottom mt-5 pt-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-9">
                                        
                                        {eventDetails.STR_EVEDISPLAYROOM === "1" && <SeatMap />}
                                        <TicketOrder eventDetails={eventDetails} />
                                        
                                        {showGetTicket && (
                                        <>
                                            {/* <RecuperationTicket /> */}

                                            <div className="card card-flush py-4 mb-10">
                                                <div className="card-body bgi-no-repeat bgi-position-center bgi-size-cover card-rounded">
                                                    <div data-kt-stepper-element="content" className="current">
                                                        {/* Wrapper */}
                                                        <div className="w-100">
                                                            <div className="pb-12">
                                                                <h1 className="fw-bold text-gray-900 text-theme">Obtenir mon ticket</h1>
                                                                <div className="text-muted fw-semibold fs-4 text-theme">
                                                                    Comment voulez-vous recevoir votre ticket ?
                                                                </div>
                                                            </div>

                                                            <div className=" bgi-no-repeat bgi-position-center bgi-size-cover card-rounded w-100">
                                                                <div className="row mb-10">
                                                                    <div className="col-xl-3 col-lg-6 d-flex flex-column h-100">
                                                                        <label className={`btn btn-outline btn-outline-dashed d-flex text-start p-6 ${selectedGetitcketOption === 1 ? 'btn-active-light-primary active' : ''}`} data-kt-button="true">
                                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                                <input className="form-check-input" type="radio" name="ticket_option" value={1} checked={selectedGetitcketOption === 1} onChange={handleOptionGetitcketChange} />
                                                                            </span>
                                                                            <span className="ms-10">
                                                                                <span className="fs-4 fw-bold text-gray-800 d-block text-theme"> <img className='' width={20} src='assets/media/telephone.png'/> Téléphone </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-3 col-lg-6 d-flex flex-column">
                                                                        <label className={`btn btn-outline btn-outline-dashed d-flex text-start p-6 ${selectedGetitcketOption === 2 ? 'btn-active-light-primary active' : ''}`} data-kt-button="true">
                                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                                <input className="form-check-input" type="radio" name="ticket_option" value={2} checked={selectedGetitcketOption === 2} onChange={handleOptionGetitcketChange} />
                                                                            </span>
                                                                            <span className="ms-10">
                                                                                <span className="fs-4 fw-bold text-gray-800 d-block text-theme"> <img className='' width={20} src='assets/media/whatsapp.png'/> Whatsapp </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-xl-3 col-lg-6 d-flex flex-column">
                                                                        <label className={`btn btn-outline btn-outline-dashed d-flex text-start p-6 ${selectedGetitcketOption === 3 ? 'btn-active-light-primary active' : ''}`} data-kt-button="true">
                                                                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                                                                <input className="form-check-input" type="radio" name="ticket_option" value={3} checked={selectedGetitcketOption === 3} onChange={handleOptionGetitcketChange} />
                                                                            </span>
                                                                            <span className="ms-10">
                                                                                <span className="fs-4 fw-bold text-gray-800 d-block text-theme"> <img className='' width={20} src='assets/media/whatsapp.png'/> Email </span>
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                                {selectedGetitcketOption === 1 && (
                                                                    <div className='row' id="telephone">
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="required fs-6 fw-semibold mb-2 text-theme">Téléphone</label>
                                                                            <div className="position-relative d-flex align-items-center">
                                                                                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4 text-theme"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                                                                <input className="form-control form-control-solid ps-12" placeholder="Renseigner votre numero de téléphone" name="STR_TICPHONE" type="tel" value={telephone} onChange={handleInputChange} />
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="col-md-6 fv-row">
                                                                            <label className="required fs-6 fw-semibold mb-2 text-theme">Email</label>
                                                                            <div className="position-relative d-flex align-items-center">
                                                                                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                                                                <input className="form-control form-control-solid ps-12" placeholder="Renseigner votre email " name="STR_TICMAIL" type="email" value={email} onChange={handleInputChange} />
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                )}

                                                                {selectedGetitcketOption === 2 && (
                                                                    <div className='row' id="whatsapp">
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="required fs-6 fw-semibold mb-2 text-theme">Numero whatsapp</label>
                                                                            <div className="position-relative d-flex align-items-center">
                                                                                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                                                                <input className="form-control form-control-solid ps-12" placeholder="Enter your WhatsApp number" name="whatsappNumber" type="tel" value={whatsappNumber} onChange={handleInputChange} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="required fs-6 fw-semibold mb-2 text-theme">Confirmé le numero</label>
                                                                            <div className="position-relative d-flex align-items-center">
                                                                                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                                                                <input className="form-control form-control-solid ps-12" placeholder="Confirm your WhatsApp number" name="confirmWhatsappNumber" type="tel" value={confirmWhatsappNumber} onChange={handleInputChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {selectedGetitcketOption === 3 && (
                                                                    <div className='row' id="email">
                                                                        {/* <div className="col-md-6 fv-row">
                                                                            <label className="required fs-6 fw-semibold mb-2 text-theme">Téléphone</label>
                                                                            <div className="position-relative d-flex align-items-center">
                                                                                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4 text-theme"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                                                                <input className="form-control form-control-solid ps-12" placeholder="Renseigner votre numero de téléphone" name="STR_TICPHONE" type="tel" value={telephone} onChange={handleInputChange} />
                                                                            </div>
                                                                        </div> */}
                                                                        <div className="col-md-6 fv-row">
                                                                            <label className="required fs-6 fw-semibold mb-2 text-theme">Email</label>
                                                                            <div className="position-relative d-flex align-items-center">
                                                                                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                                                                <input className="form-control form-control-solid ps-12" placeholder="Renseigner votre email " name="STR_TICMAIL" type="email" value={email} onChange={handleInputChange} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}

                                                                {/* {shouldShowConfirmation() && (
                                                                    <div className="mt-3">
                                                                        <div className="alert alert-success" role="alert">
                                                                            Vos informations sont confirmées.
                                                                        </div>
                                                                    </div>
                                                                )} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {shouldShowConfirmation() && ()} */}
                                        
                                            <div className="card card-flush py-4 mb-10">
                                                <div className="card-body bgi-no-repeat bgi-position-center bgi-size-cover card-rounded">
                                                    <div data-kt-stepper-element="content" className="current">
                                                        <div className="w-100">
                                                            <div className="pb-12">
                                                                <h1 className="fw-bold text-gray-900 text-theme">Payer mon ticket</h1>
                                                                <div className="text-muted fw-semibold fs-4 text-theme">
                                                                    If you need more info, please check{' '}
                                                                    <a href="#" className="link-primary text-theme">Project Guidelines</a>
                                                                </div>
                                                            </div>
                                                            <div className="checkout-widget checkout-card bg-white p-0">
                                                                <div className='row'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6 '>
                                                                            <ul className="payment-option mb-0">
                                                                                {paymentOptions.map(option => (
                                                                                    <li
                                                                                        key={option.id}
                                                                                        className={`pb-0 ${selectedOption === option.id ? 'active' : ''}`}
                                                                                        onClick={() => handleOptionClick(option.id)}
                                                                                    >
                                                                                        <a>
                                                                                            <img src={option.image} alt={option.name} />
                                                                                            <span>{option.name}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                ))}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {selectedOption && (
                                                                    <div>
                                                                        {/* 
                                                                        <h6 className="subtitle">
                                                                            Entrez les détails de votre {paymentOptions.find(option => option.id === selectedOption).name}
                                                                        </h6>
                                                                        */}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div>
                                                                {selectedOption === 1 && (
                                                                    <form id='mobile-money' className="payment-card-form">
                                                                        
                                                                        <div className='row'>
                                                                            <div className="col-md-4 fv-row">
                                                                                <label className="required fs-6 fw-semibold mb-2 text-theme">Nom</label>
                                                                                <div className="position-relative d-flex align-items-center">
                                                                                    <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                                                                                        <span className="path1" />
                                                                                        <span className="path2" />
                                                                                    </i>
                                                                                    <input type="text" className="form-control form-control-solid bg-gray-500" placeholder="Enter Target Title" name="target_title" />
                                                                                    </div>
                                                                            </div>
                                                                            <div className="col-md-4 fv-row">
                                                                                <label className="required fs-6 fw-semibold mb-2">Prénom</label>
                                                                                <div className="position-relative d-flex align-items-center">
                                                                                    <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                                                                                        <span className="path1" />
                                                                                    </i>
                                                                                    <input type="text" className="form-control form-control-solid bg-gray-500" placeholder="Enter Target Title" name="target_title" />
                                                                                    </div>
                                                                            </div>
                                                                            <div className="col-md-4 fv-row">
                                                                                <label className="required fs-6 fw-semibold mb-2 text-theme">Email</label>
                                                                                <div className="position-relative d-flex align-items-center">
                                                                                    <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                                                                                        <span className="path1" />
                                                                                        <span className="path2" />
                                                                                    </i>
                                                                                    <input type="text" className="form-control form-control-solid bg-gray-500" placeholder="Enter Target Title" name="email" />
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                                {selectedOption === 2 && (
                                                                    <form id='mobile-money' className="payment-card-form">
                                                                        <div className="notice bg-light-success rounded border-success border border-dashed mb-9 p-6">
                                                                            <div className="d-flex flex-column fv-row fv-plugins-icon-container">
                                                                                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                                                                                    <span className="required text-theme">Numero de paiement</span>
                                                                                    <span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                                                                        <i className="ki-duotone ki-information-5 text-gray-500 fs-6">
                                                                                            <span className="path1"></span>
                                                                                            <span className="path2"></span>
                                                                                            <span className="path3"></span>
                                                                                        </i>
                                                                                    </span>
                                                                                </label>
                                                                                <input type="text" className="form-control form-control-solid bg-white"
                                                                                id="STR_TICPHONEPAYMENT_MTN"
                                                                                name="STR_TICPHONEPAYMENT_MTN"
                                                                                value={mtnPhonePayment}
                                                                                onChange={handleInputChange}
                                                                                placeholder="Nouméro de paiement" />
                                                                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row'>
                                                                            <div className="col-md-4 fv-row">
                                                                                <label className="required fs-6 fw-semibold mb-2 text-theme">Nom</label>
                                                                                <div className="position-relative d-flex align-items-center">
                                                                                    <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                                                                                        <span className="path1" />
                                                                                        <span className="path2" />
                                                                                    </i>
                                                                                    <input type="text" className="form-control form-control-solid bg-gray-500" placeholder="Enter Target Title" name="target_title" />
                                                                                    </div>
                                                                            </div>
                                                                            <div className="col-md-4 fv-row">
                                                                                <label className="required fs-6 fw-semibold mb-2">Prénom</label>
                                                                                <div className="position-relative d-flex align-items-center">
                                                                                    <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                                                                                        <span className="path1" />
                                                                                    </i>
                                                                                    <input type="text" className="form-control form-control-solid bg-gray-500" placeholder="Enter Target Title" name="target_title" />
                                                                                    </div>
                                                                            </div>
                                                                            <div className="col-md-4 fv-row">
                                                                                <label className="required fs-6 fw-semibold mb-2 text-theme">Email</label>
                                                                                <div className="position-relative d-flex align-items-center">
                                                                                    <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                                                                                        <span className="path1" />
                                                                                        <span className="path2" />
                                                                                    </i>
                                                                                    <input type="text" className="form-control form-control-solid bg-gray-500" placeholder="Enter Target Title" name="target_title" />
                                                                                    </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                                {selectedOption === 3 && (
                                                                    <form id='visa' className="payment-card-form">
                                                                    
                                                                        <div data-kt-stepper-element="content" className="current">
                                                                            <div className="w-100">
                                                                            {/*begin::Input group*/}
                                                                            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                                                                                {/*begin::Label*/}
                                                                                <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                                                                <span className="required text-theme">Name On Card</span>
                                                                                <span className="ms-1" data-bs-toggle="tooltip" aria-label="Specify a card holder's name" data-bs-original-title="Specify a card holder's name" data-kt-initialized={1}>
                                                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6"><span className="path1" /><span className="path2" /><span className="path3" /></i></span>  </label>
                                                                                {/*end::Label*/}
                                                                                <input type="text" className="form-control form-control-solid" placeholder name="card_name" defaultValue="Max Doe" />
                                                                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                                                                            {/*begin::Input group*/}
                                                                            <div className="d-flex flex-column mb-7 fv-row fv-plugins-icon-container">
                                                                                {/*begin::Label*/}
                                                                                <label className="required fs-6 fw-semibold form-label mb-2 text-theme">Card Number</label>
                                                                                <div className="position-relative">
                                                                                {/*begin::Input*/}
                                                                                <input type="text" className="form-control form-control-solid" placeholder="Enter card number" name="card_number" defaultValue="4111 1111 1111 1111" />
                                                                                {/*end::Input*/}
                                                                                <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                                                                                    <img src="../../../assets/media/svg/card-logos/visa.svg" alt="" className="h-25px" />
                                                                                    <img src="../../../assets/media/svg/card-logos/mastercard.svg" alt="" className="h-25px" />
                                                                                    <img src="../../../assets/media/svg/card-logos/american-express.svg" alt="" className="h-25px" />
                                                                                </div>
                                                                                </div>
                                                                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                                                                            <div className="row mb-10">
                                                                                <div className="col-md-8 fv-row">
                                                                                <label className="required fs-6 fw-semibold form-label mb-2 text-theme">Expiration Date</label>
                                                                                <div className="row fv-row fv-plugins-icon-container">
                                                                                    <div className="col-6">
                                                                                    <select name="card_expiry_month" className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Month" data-select2-id="select2-data-19-ik1r" tabIndex={-1} aria-hidden="true" data-kt-initialized={1}>
                                                                                        <option data-select2-id="select2-data-21-4796" />
                                                                                        <option value={1}>1</option>
                                                                                        <option value={2}>2</option>
                                                                                    </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-20-ivqn" style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-card_expiry_month-b5-container" aria-controls="select2-card_expiry_month-b5-container"><span className="select2-selection__rendered" id="select2-card_expiry_month-b5-container" role="textbox" aria-readonly="true" title="Month"><span className="select2-selection__placeholder">Month</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                                                                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                                                                                    {/*end::Col*/}
                                                                                    <div className="col-6">
                                                                                    <select name="card_expiry_year" className="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Year" data-select2-id="select2-data-22-80o8" tabIndex={-1} aria-hidden="true" data-kt-initialized={1}>
                                                                                        <option data-select2-id="select2-data-24-0cvw" />
                                                                                        <option value={2024}>2024</option>
                                                                                        <option value={2025}>2025</option>
                                                                                        <option value={2026}>2026</option>
                                                                                    </select><span className="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-23-szaa" style={{width: '100%'}}><span className="selection"><span className="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-card_expiry_year-c4-container" aria-controls="select2-card_expiry_year-c4-container"><span className="select2-selection__rendered" id="select2-card_expiry_year-c4-container" role="textbox" aria-readonly="true" title="Year"><span className="select2-selection__placeholder">Year</span></span><span className="select2-selection__arrow" role="presentation"><b role="presentation" /></span></span></span><span className="dropdown-wrapper" aria-hidden="true" /></span>
                                                                                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                                                                                </div>
                                                                                </div>
                                                                                
                                                                                <div className="col-md-4 fv-row fv-plugins-icon-container">
                                                                                {/*begin::Label*/}
                                                                                <label className="d-flex align-items-center fs-6 fw-semibold form-label mb-2">
                                                                                    <span className="required text-theme">CVV</span>
                                                                                    <span className="ms-1" data-bs-toggle="tooltip" aria-label="Enter a card CVV code" data-bs-original-title="Enter a card CVV code" data-kt-initialized={1}>
                                                                                    <i className="ki-duotone ki-information-5 text-gray-500 fs-6"><span className="path1" /><span className="path2" /><span className="path3" /></i></span>      </label>
                                                                                <div className="position-relative">
                                                                                    <input type="text" className="form-control form-control-solid" minLength={3} maxLength={4} placeholder="CVV" name="card_cvv" />
                                                                                    <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                                                                    <i className="ki-duotone ki-credit-cart fs-2hx"><span className="path1" /><span className="path2" /></i>          </div>
                                                                                </div>
                                                                                <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback" /></div>
                                                                            </div>
                                                                            <div className="d-flex flex-stack">
                                                                                <div className="me-5">
                                                                                    <label className="fs-6 fw-semibold form-label text-theme">Save Card for further billing?</label>
                                                                                    <div className="fs-7 fw-semibold text-muted text-theme">If you need more info, please check budget planning</div>
                                                                                </div>
                                                                                <label className="form-check form-switch form-check-custom form-check-solid">
                                                                                    <input className="form-check-input" type="checkbox" defaultValue={1} defaultChecked="checked" />
                                                                                    <span className="form-check-label fw-semibold text-muted text-theme">
                                                                                        Save Card
                                                                                    </span>
                                                                                </label>
                                                                            </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </form>
                                                                )}

                                                                <div className={`notice d-flex mt-10  align-items-center rounded border border-dashed mb-5 p-6 h-100`}>
                                                                    <input className="form-check-input mx-3" type="checkbox" name="conditions[]" value="accept" onChange={handleConditionsChange} />
                                                                    <div className="d-flex flex-stack flex-grow-1 ">
                                                                        <div className="fw-semibold ms-10">
                                                                            <h4 className="text-gray-900 fw-bold m-0 ms-5 fs-4 text-theme">
                                                                                Acceptez les  <a className='text-danger'> conditions générales de vente et la politique de confidentialité</a>
                                                                            </h4>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className='row mt-4'>
                                                                    <div className="col-lg-12 fv-row">
                                                                    <button className={`btn btn-lg rounded fs-14 pull-right ${conditionsAccepted ? 'btn-success' : 'btn-secondary'}`}
                                                                            disabled={!conditionsAccepted} onClick={handlePayment}>
                                                                            Effectuer le Paiement
                                                                        </button>                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        )}
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
                                            <div className="card card-flush py-4">
                                                <div className="card-header">
                                                <div className="card-title">
                                                    <h2 className='text-theme'>Description</h2>
                                                </div>
                                                </div>
                                                <div className="card-body pt-0">
                                                <span className="text-muted">
                                                {eventDetails.STR_EVEDESCRIPTION}
                                                </span>
                                                </div>
                                            </div>

                                            <div className="card card-flush py-4">
                                                <div className="card-header">
                                                <div className="card-title">
                                                    <h2 className='text-theme'>Annonceur</h2>
                                                </div>
                                                </div>
                                                <div className="card-body text-center pt-0 bgi-no-repeat bgi-position-center bgi-size-cover card-rounded min-h-250px" >

                                                <div className="image-input image-input-empty image-input-outline image-input-placeholder mb-3"
                                                    data-kt-image-input="true" >
                                                    <div className="image-input-wrapper w-150px h-150px" style={{ backgroundImage: `url(${urlBaseImage + eventDetails.STR_EVEBANNER})`, backgroundSize: "cover", backgroundPosition: "center" }} />
                                                    </div>

                                                <div className="text-bold fs-7 text-theme">
                                                {eventDetails.STR_EVEANNONCEUR}
                                                </div>
                                                <div className="text-muted fs-7 text-theme">
                                                {eventDetails.STR_EVEANNONCEURDESC}
                                                </div>
                                                </div>
                                            </div>

                                            {showGetTicket && (

                                                <div className="card card-flush rounded border-warning border border-dashed py-4">
                                                    <div className="card-header">
                                                    <div className="card-title">
                                                        <h2 className='text-theme'>Mise au point des réservations</h2>
                                                    </div>
                                                    </div>
                                                    <div className="card-body pt-0">
                                                    <span className="text-muted">
                                                    {/* {evenement.STR_EVEDESCRIPTION} */}
                                                        {cartItems.map((item, index) => (
                                                            <Reservations key={index} pannierData={item} index={index} />
                                                        ))}
                                                    </span>
                                                    <button class="btn btn-light-warning fs-3 fw-bolder w-100 py-5 mb-13" data-bs-toggle="modal" data-bs-target="#kt_modal_create_app">
                                                       Total: {totalAmount} GNF
                                                    </button>
                                                    <button class="btn btn-primary fs-3 fw-bolder w-100 py-5" data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">Annuler la réservation</button>

                                                    </div>
                                                </div>
                                             )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {loading && (
                <div className="loader">
                    <div className="spinner"></div>
                </div>
            )}
            <Footer />
        </>
    );
}

export default Detail;
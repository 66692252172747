import React, { useState, useContext, useEffect } from 'react';
import { CartContext } from '../../contexts/CartContext';

const PanierItem = ({ pannierData, index, onRemove }) => {
  const [quantity, setQuantity] = useState(pannierData.quantity);
  const [INT_ELINUMBER, setINT_ELINUMBER] = useState(pannierData.quantity);
  const { updateCartItems, cartItems } = useContext(CartContext);
  const maxPurchase = parseInt(pannierData.STR_MAX_PURCHASE);

  useEffect(() => {
    const cartItem = cartItems.find(item => item.LG_EVEID === pannierData.LG_EVEID && item.category === pannierData.category);
    if (cartItem) {
      setQuantity(cartItem.quantity);
      setINT_ELINUMBER(cartItem.quantity);
    }
  }, [cartItems, pannierData.LG_EVEID, pannierData.category]);

  const incrementQuantity = () => {
    const newQuantity = quantity + 1;
    if (newQuantity <= maxPurchase) {
      updateCart(index, newQuantity);
      setQuantity(newQuantity);
      setINT_ELINUMBER(newQuantity);
    }
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1;
      updateCart(index, newQuantity);
      setQuantity(newQuantity);
      setINT_ELINUMBER(newQuantity);
    }
  };

  const updateCart = (index, newQuantity) => {
    const updatedCartItems = [...cartItems];
    const itemIndex = updatedCartItems.findIndex(item => item.LG_EVEID === pannierData.LG_EVEID && item.category === pannierData.category);
    if (itemIndex !== -1) {
      updatedCartItems[itemIndex].quantity = newQuantity;
      updatedCartItems[itemIndex].INT_ELINUMBER = newQuantity;
      updatedCartItems[itemIndex].totalPrice = updatedCartItems[itemIndex].DBL_TICAMOUNT * newQuantity;
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      updateCartItems(updatedCartItems);
    }
  };

  return (
    <div className="card card-dashed bg-light h-xl-100 flex-row flex-stack flex-wrap p-6 mb-5">
      <div className="d-flex flex-row w-100">
        <div className="d-flex flex-column w-100 py-2">
          <div className="d-flex align-items-center fs-4 fw-bold mb-5">
            <div className="w-100">
              <span className="truncate-2-lines">{pannierData.STR_EVENAME}</span>
            </div>
          </div>
          <span className="badge badge-light-success fs-7 ms-2">{pannierData.STR_EVESTATUTFREE}</span>

          <div className="d-flex align-items-center">
            <img
              src={pannierData.STR_EVEPIC_PANIER}
              alt=""
              height={48}
              className="me-4"
            />
            <div>
              <div className="fs-4 fw-bold">{pannierData.category}</div>
              <div className="fs-6 fw-semibold text-gray-800">
                <i className="fas fa-calendar-alt"></i><span>{pannierData.DT_EVEBEGIN} </span> | <i className="far fa-clock"></i><span>{pannierData.HR_EVEBEGIN} </span>
              </div>
              <div className="fs-6 fw-semibold text-gray-800">
                <i className="fas fa-map fs-10"></i><span>{pannierData.HR_EVEBEGIN} </span> | <i className="far fa-clock"></i><span>15:00 </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-stack">
          <div className="d-flex align-items-center h-100 py-2 mb-7">
            <button
              className="btn btn-sm btn-light me-3 fs-1"
              onClick={decrementQuantity}
            >
              -
            </button>
            <span className="fs-4 fw-bold mx-3">{quantity}</span>
            <button
              className="btn btn-sm btn-light me-3 fs-1"
              onClick={incrementQuantity}
              disabled={quantity >= maxPurchase}
            >
              +
            </button>
            <button
              className="btn btn-sm btn-light-danger btn-active-light-danger"
              onClick={onRemove}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="card-footer pt-0">
        <div className="d-flex flex-column w-100">
          <div className="d-flex flex-stack fs-6 fw-semibold">
            <span className="text-muted me-2">Prix unitaire:</span>
            <span className="text-gray-800">{pannierData.price} FCFA</span>
          </div>
          <div className="d-flex flex-stack fs-6 fw-semibold">
            <span className="text-muted me-2">Prix total:</span>
            <span className="text-gray-800">{pannierData.totalPrice} FCFA</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanierItem;
